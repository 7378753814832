<template>
  <div>
    <div class="NumberWisdom">
      <img src="../assets/images/技术改造.jpg" alt="" />
      <div class="button">
        <div v-if="!isLogin" @click="handleClickToLogin">立即登录</div>
        <div
          @click="handleClickApply"
          v-if="reviewStatus == '1' && loginType == 'qiye'"
        >
          立即申请
        </div>
        <div
          style="margin-top: 10px;"
          @click="handleClickConsequence"
          v-if="reviewStatus == '0' && loginType == 'qiye'"
        >
          结果查询
        </div>
        <div
          style="margin-top: 10px;"
          v-if="reviewStatus == '2' && loginType == 'qiye'"
          @click="handleClickApply"
        >
          内容填报
        </div>
      </div>
    </div>
    <div class="mainwidth">
      <div class="info">
        <h2>技术改造专项贷款申请计划</h2>
        <div class="limit">
          <p style="font-weight: bold;">可用额度</p>
          <a-divider></a-divider>
          <p style="margin-top: 10px">授信额度:500万-2000万</p>
          <p>贷款期限:1年-3年</p>
        </div>
        <div class="condition" style="margin-top: 20px;margin-bottom: 20px">
          <p style="font-weight: bold;">贷款条件</p>
          <a-divider></a-divider>
          <!-- <p style="margin-top: 10px">
          企业评估:通过龙哈产融云平台《企业数据分析》评估报告。
        </p> -->
          <p>
            哈尔滨区域内注册的有明确数字转型、智能化改造需求的制造型企业。
          </p>
          <p>经营历史和信用记录:具有稳定的经营历史和良好的信用记录。</p>
          <!-- <p>具有明确的技术改造计划,有具体实施方案和时间表。</p> -->
        </div>
        <!-- <div class="condition" style="margin-top: 20px;">
        <p style="font-weight: bold;">担保方式</p>
        <a-divider></a-divider>
        <p>经开区担保公司信用担保</p>
      </div> -->
        <!-- v-if="policyList.length>0" -->
        <div
          class="support"
          style="margin-bottom: 20px;"
          v-if="policyList.length > 0"
        >
          <p style="font-weight: bold;">政策支持</p>
          <a-divider></a-divider>
          <p v-for="(item, index) in policyList" :key="index">
            <span style="margin-right: 10px;">{{ item.title }}</span
            ><a @click="handleClickDownload(item)">下载</a>
          </p>
          <!-- <p><span>《黑龙江省推动大规模设备更新和消费品》</span><a>下载</a></p>
          <p><span>《国家产融合作试点域市典型案例集》</span><a>下载</a></p> -->
        </div>
        <!-- <div class="lj">
        <a-button
          type="primary"
          style="width: 200px;height: 40px;"
          @click="handleClickApply"
          >立即申请</a-button
        >
      </div> -->
      </div>
      <!-- 首次弹窗 -->
      <a-modal
        v-model:visible="visibleShow"
        title="贷款所需材料"
        @cancel="cancel"
        width="40%"
        :maskClosable="false"
        :footer="null"
      >
        <div class="item">
          <p>融资诊断</p>
          <div class="button-wrap">
            <p @click="handleClickDiagnose" v-if="isFlag.if_diagnosis == '1'">
              立即诊断
            </p>
            <p @click="handleClickResult" v-if="isFlag.if_diagnosis == '0'">
              诊断结果
            </p>
          </div>
        </div>
        <div class="item">
          <p>企业改造方案上传</p>
          <div class="button-wrap">
            <p v-if="isFlag.option1 == false" @click="handleClickGZ">
              立即上传
            </p>
            <p v-if="isFlag.option1 == true">已上传</p>
          </div>
        </div>
        <div class="item">
          <p>技术改造合同或设备采购协议上传</p>
          <div class="button-wrap">
            <p v-if="isFlag.option2 == false" @click="handleClickHTXY">
              立即上传
            </p>
            <p v-if="isFlag.option2 == true">已上传</p>
          </div>
        </div>
        <div class="success-title" v-if="reviewStatus == '0'">
          恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。
        </div>
        <div class="success-text" v-if="reviewStatus == '0'">
          <p style="font-weight: bold;">
            如需进一步了解请拨打业务专线进行咨询:
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;"
              >数字化转型服务:</span
            ><span style="margin-left: 10px">卢思冰</span
            ><span style="color: #1089ff;margin-left: 5px;">15765999441</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">融资服务:</span>
            <span style="margin-left: 10px">李志伟</span
            ><span style="color: #1089ff;margin-left: 5px;">18245108077</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">政策申请: </span
            ><span style="margin-left: 10px">侯惠宁</span
            ><span style="color: #1089ff;margin-left: 5px;">18745349632</span>
          </p>
        </div>
      </a-modal>
      <!-- 结果查询弹窗-最终 -->
      <a-modal
        v-model:visible="resultShow"
        title="结果查询"
        width="35%"
        :footer="null"
      >
        <div class="success-title">
          恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。
        </div>
        <div class="success-text">
          <p style="font-weight: bold;">
            如需进一步了解请拨打业务专线进行咨询:
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;"
              >数字化转型服务:</span
            ><span style="margin-left: 10px">卢思冰</span
            ><span style="color: #1089ff;margin-left: 5px;">15765999441</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">融资服务:</span>
            <span style="margin-left: 10px">李志伟</span
            ><span style="color: #1089ff;margin-left: 5px;">18245108077</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">政策申请: </span
            ><span style="margin-left: 10px">侯惠宁</span
            ><span style="color: #1089ff;margin-left: 5px;">18745349632</span>
          </p>
        </div>
      </a-modal>
      <!--企业改造弹窗 -->
      <a-modal
        v-model:visible="visibleUploadShow"
        title="企业改造方案"
        @ok="handleOk"
        width="40%"
        :maskClosable="false"
      >
        <a-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          layout="vertical"
        >
          <a-form-item label="企业改造方案">
            <a-textarea
              v-model:value="content"
              placeholder="方案介绍"
              style="min-height: 200px;"
            />
          </a-form-item>
          <a-form-item label="附件:">
            <a-upload
              name="file"
              :action="$uploadApiSB"
              :multiple="true"
              :before-upload="beforeUpload"
              @change="handleChange"
              accept=".pdf,.docx"
              :data="{
                files: fileList,
              }"
            >
              <a-button> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-modal>
      <!--合同协议弹窗 -->
      <a-modal
        v-model:visible="visibleUploadShow1"
        title="技术改造合同或设备采购协议上传"
        @ok="handleOk1"
        width="40%"
        :maskClosable="false"
      >
        <a-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          layout="vertical"
        >
          <a-form-item label="技术改造合同或设备采购协议">
            <a-textarea
              v-model:value="content1"
              placeholder="方案介绍"
              style="min-height: 200px;"
            />
          </a-form-item>
          <a-form-item label="附件:">
            <a-upload
              name="file"
              :action="$uploadApiSB"
              :multiple="true"
              :before-upload="beforeUpload1"
              @change="handleChange1"
              accept=".pdf,.docx"
              :data="{
                files: fileList1,
              }"
            >
              <a-button> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isFinish, specialLoanFileAdd } from "../api/digitization/index";
import { getPolicyList } from "../api/financialPrefecture";
export default {
  name: "Remould",
  data() {
    return {
      wrapperCol: {
        span: 24,
      },
      content: "",
      visibleShow: false,
      visibleUploadShow: false,
      visibleUploadShow1: false,
      resultShow: false,
      fileList: [],
      fileList1: [],
      uploadedPaths: [], // 用于存储成功上传的文件路径
      uploadedPaths1: [], // 用于存储成功上传的文件路径
      // person: "",
      // phone: "",
      isFlag: {},
      params: {},
      policyList: [],
      reviewStatus: "",
    };
  },
  computed: {
    ...mapGetters([
      "orgId",
      "isAuth",
      "loginType",
      "orgName",
      "isLogin",
      "userId",
    ]),
    // shouldShowDiv() {
    //   // 计算异或逻辑
    //   const countTrue =
    //     (this.isFlag.option1 == true ? 1 : 0) +
    //     (this.isFlag.if_diagnosis == 0 ? 1 : 0) +
    //     (this.isFlag.option2 == true ? 1 : 0);
    //   // 只有当恰好有一个条件为真时才显示
    //   return countTrue == 1;
    // },
  },
  methods: {
    // 未登录显示的登录按钮事件
    handleClickToLogin() {
      this.$router.push("/");
    },
    // 立即申请
    handleClickApply() {
      this.visibleShow = true;
      this.getFlag();
    },
    // 弹窗ok事件
    handleOk() {
      if (!this.content) {
        this.$message.error("请填写方案介绍");
        return false;
      } else if (this.uploadedPaths.length <= 0) {
        this.$message.error("请上传企业改造方案");
        return false;
      }
      let a = [];
      this.uploadedPaths.forEach((item) => {
        let obj = {};
        obj.file_url = item.response.pd[0].url;
        obj.file_name = item.response.pd[0].name;
        a.push(obj);
      });
      let params = {
        special_loan_info_id: this.params.special_loan_info_id,
        file_type: "1",
        pd: a,
        content: this.content,
        create_by:localStorage.getItem("userName")
      };
      specialLoanFileAdd(params).then((res) => {
        if (res.result == "success") console.log(res);
        this.$message.success("企业改造方案提交成功");
        this.visibleUploadShow = false;
        this.getFlag();
      });
    },
    // 弹窗ok事件
    handleOk1() {
      if (!this.content1) {
        this.$message.error("请填写方案介绍");
        return false;
      } else if (this.uploadedPaths1.length <= 0) {
        this.$message.error("请上传技术改造合同或设备采购协议");
        return false;
      }
      let a = [];
      this.uploadedPaths1.forEach((item) => {
        let obj = {};
        obj.file_url = item.response.pd[0].url;
        obj.file_name = item.response.pd[0].name;
        a.push(obj);
      });
      let params = {
        special_loan_info_id: this.params.special_loan_info_id,
        file_type: "2",
        pd: a,
        content: this.content1,
        create_by:localStorage.getItem("userName")
      };
      specialLoanFileAdd(params).then((res) => {
        if (res.result == "success") console.log(res);
        this.$message.success("技术改造合同或设备采购协议提交成功");
        this.visibleUploadShow1 = false;
        this.getFlag();
      });
    },
    cancel() {
      this.getFlag();
    },
    // 立即诊断
    handleClickDiagnose() {
      if (this.loginType === "qiye" && this.isAuth === "N") {
        this.$message.info("请先完成企业认证或个体认证后才能进入");
        this.$router.push("/PersonalLayout/WareHouse");
        return false;
      } else {
        this.$router.push("/PersonalLayout/InDepthAnalysisReport");
      }
    },
    // 诊断结果
    handleClickResult() {
      this.$router.push("/PersonalLayout/InDepthAnalysisReport");
    },
    // 最终结果查询
    handleClickConsequence() {
      // this.resultShow = true;
      this.visibleShow = true;
    },
    // 改造方案上传按钮
    handleClickGZ() {
      this.visibleUploadShow = true;
    },
    // 合同协议上传按钮
    handleClickHTXY() {
      this.visibleUploadShow1 = true;
    },
    beforeUpload(file) {
      const isPDForWord =
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      if (!isPDForWord) {
        this.$message.error("上传文件只能是 PDF 或 Word 格式!");
      }
      this.fileList = file;
      return isPDForWord;
    },
    handleChange({ fileList }) {
      this.uploadedPaths = fileList;
    },
    beforeUpload1(file) {
      const isPDForWord =
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      if (!isPDForWord) {
        this.$message.error("上传文件只能是 PDF 或 Word 格式!");
      }
      this.fileList1 = file;
      return isPDForWord;
    },
    handleChange1({ fileList }) {
      this.uploadedPaths1 = fileList;
    },
    // 下载政策
    handleClickDownload(item) {
      if (item.url) {
        const urls = item.url.split(","); // 将URL字符串分割成数组
        urls.forEach((url) => {
          window.open(url.trim(), "_blank"); // 使用trim()去除可能的空白字符
        });
      } else {
        this.$message.info("无附件");
      }
      // console.log(item.url);
      // if (item.url) {
      //   window.open(item.url, "_blank");
      // } else {
      //   this.$message.info("无附件");
      // }
    },
    // 查询状态
    getFlag() {
      let params = {
        enterprise_id: this.orgId,
        loan_type: 2,
      };
      isFinish(params).then((res) => {
        console.log(res);

        this.isFlag = res.pd;
        // this.person = this.isFlag.contact_person;
        // this.phone = this.isFlag.contact_phone;
        this.params.special_loan_info_id = res.pd.special_loan_info_id;
        this.reviewStatus = res.pd.review_status;
        console.log(this.reviewStatus);
      });
    },
    getList() {
      getPolicyList({
        type: 2,
        user_id: this.userId ? this.userId : "null",
      }).then((res) => {
        console.log(res);

        this.policyList = res.varList;
      });
    },
  },
  mounted() {
    if (this.orgId) {
      this.getFlag();
    }
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.NumberWisdom {
  position: relative;
  // height: 458px;
  width: 1440px;
  margin: 0 auto;
  img {
    // height: 100%;
    // width: 100%;
  }
  .button {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    left: 50%;
    margin-left: -90px;
    // transform: translateX(-50%);
    div {
      background: #4095e5;
      color: #fff;
      width: 180px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
.info {
  margin-top: 20px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .limit,
  .condition,
  .condition,
  .support {
    box-shadow: 0px 2px 10px #929292;
    padding: 10px 30px;
    font-size: 18px;
    // font-weight: bold;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    p {
      margin-bottom: 10px;
    }
  }
  .lj {
    width: 90%;
    margin: 20px auto;
    text-align: center;
    // margin: 20px 0px;
  }
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
::v-deep .ant-modal-title {
  font-size: 18px !important;
  font-weight: bold !important;
}
.button-wrap {
  p {
    box-shadow: 0px 0px 10px #929292;
    border-radius: 5px;
    cursor: pointer;
    width: 80px;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
}
.success-title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
.success-text {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
</style>
